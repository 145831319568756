import abi from "./abis/src/contracts/Adulam.sol/Nft1Abi.json";
import address from "./abis/nft1ContractAddress.json";
import { getGlobalState, setGlobalState } from "./store";
import { ethers } from "ethers";

const { ethereum } = window;
const contractAddress = address.address;
const contractAbi = abi.abi;

const getEthereumContract = () => {
  const connectedAccount = getGlobalState("connectedAccount");
  if (connectedAccount) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAddress, contractAbi, signer);

    return contract;
  } else {
    return null;
  }
};

const isWalletConnected = async () => {
  try {
    if (!ethereum) {
      alert("Please install Metamask or open it on");
      return false;
    }
    const accounts = await ethereum.request({ method: "eth_accounts" });
    window.ethereum.on("chainChanged", () => {
      window.location.reload();
    });

    window.ethereum.on("accountsChanged", async (newAccounts) => {
      setGlobalState("connectedAccount", newAccounts[0]);
    });

    if (accounts.length) {
      setGlobalState("connectedAccount", accounts[0]);
      return true;
    } else {
      setGlobalState("connectedAccount", null); // Clear the connected account
      alert("Connect your wallet to view or use this dApp.");
      console.log("No accounts found.");
      return false;
    }
  } catch (error) {
    reportError(error);
    return false;
  }
};

const connectWallet = async () => {
  try {
    if (!ethereum) return alert("Please install Metamask");
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    setGlobalState("connectedAccount", accounts[0]);
  } catch (error) {
    reportError(error);
  }
};

const mint = async (numNFTs, referrerAddress) => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const connectedAccount = getGlobalState("connectedAccount", accounts[0]);
    const contract = getEthereumContract();

    // Fetch current mint fee
    const mintFee = await contract.getMintFee();

    // Calculate total cost based on number of NFTs
    const totalCost = mintFee.mul(numNFTs);

    // Execute transaction
    const amount = totalCost;
    await contract.mint(numNFTs, referrerAddress, {
      from: connectedAccount,
      value: amount._hex,
    });

    await isWalletConnected();
  } catch (error) {
    reportError(error);
  }
};

const reportError = (error) => {
  console.log(error.message);
  throw new Error("No ethereum object.");
};

const getTotalSupply = async () => {
  try {
    const contract = getEthereumContract();
    const totalSupply = await contract.totalSupply();
    return totalSupply.toString();
  } catch (error) {
    reportError(error);
  }
};

const getMaxSupply = async () => {
  try {
    const contract = getEthereumContract();
    const maxSupply = await contract.getMaxSupply();
    return maxSupply.toString();
  } catch (error) {
    reportError(error);
  }
};

const getMintFee = async () => {
  try {
    const contract = getEthereumContract();
    const mintFee = await contract.getMintFee();
    return mintFee.toString();
  } catch (error) {
    reportError(error);
  }
};
const getName = async () => {
  try {
    const contract = getEthereumContract();
    const name = await contract.name();
    return name.toString();
  } catch (error) {
    reportError(error);
  }
};
const getTokenURI = async () => {
  try {
    const contract = getEthereumContract();
    const URI = await contract._tokenURI();
    return URI.toString();
  } catch (error) {
    reportError(error);
  }
};

export {
  isWalletConnected,
  getEthereumContract,
  connectWallet,
  mint,
  getTotalSupply,
  getMaxSupply,
  getMintFee,
  getName,
  getTokenURI,
};
