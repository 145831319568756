import React, { useEffect, useState } from "react";
import Hpclogo from "../assets/Hpclogo.png";
import HpcStakinglogo from "../assets/hpcstaking.png";
import { setAlert, setGlobalState, useGlobalState } from "../store";
import {
  connectWallet,
  stakeTokens,
  unstakeTokens,
  compoundRewards,
  claimRewards,
  myStakes,
  getClaimInterval,
  getTotalStaked,
  totalStakers,
  currentAPY,
  myRewards,
  getRewardPerStaker,
  myTotalRewardsReceived,
  getTotalRewardAmount,
  Rcalculator,
  totalContractBal,
} from "../HpcStaking";

const HpcStakingHero = () => {
  const [connectedAccount] = useGlobalState("connectedAccount");
  const [rewardPerStaker, setRewardPerStaker] = useState("");
  const [totalStakes, setTotalStaked] = useState("");
  const [apy, setCurrentAPY] = useState("");
  const [claimInterval, setClaimInterval] = useState("");
  const [userRewards, setMyRewards] = useState("");
  const [myStake, setMyStake] = useState("");
  const [totalStaker, setTotalStakers] = useState("");
  const [stakeAmount, setStakeAmount] = useState("");
  const [unstakeAmount, setUnstakeAmount] = useState("");
  const [compoundAmount, setCompoundAmount] = useState("");
  const [myTotalRewardClaimed, setMyTotalRewardsClaimed] = useState("");
  const [totalRewardAmount, setTotalRewardsAmount] = useState("");
  const [rCalculatorAmount, setRCalculatorAmount] = useState("");
  const [rCalculatorResult, setRCalculatorResult] = useState("");
  const [totalCB, setTotalContractBal] = useState("");

  useEffect(() => {
    const fetchTotalContractBal = async () => {
      const rClaimed = await totalContractBal();
      const result = (rClaimed / 10 ** 8).toFixed(8);
      setTotalContractBal(result);
    };
    const fetchTotalRewardsAmount = async () => {
      const rClaimed = await getTotalRewardAmount();
      const result = (rClaimed / 10 ** 8).toFixed(8);
      setTotalRewardsAmount(result);
    };
    const fetchMyTotalRewardsClaimed = async () => {
      const rClaimed = await myTotalRewardsReceived();
      const result = (rClaimed / 10 ** 8).toFixed(8);
      setMyTotalRewardsClaimed(result);
    };
    const fetchtotalStaked = async () => {
      const tStaked = await getTotalStaked();
      const result = (tStaked / 10 ** 8).toFixed(8);
      setTotalStaked(result);
    };
    const fetchCurrentAPY = async () => {
      const cApy = await currentAPY();
      const result = (cApy / 10 ** 19).toFixed(5);
      setCurrentAPY(result);
    };
    const fetchMyRewards = async () => {
      const Mrewards = await myRewards();
      const result = (Mrewards / 10 ** 8).toFixed(8);
      setMyRewards(result);
    };
    const fetchClaimInterval = async () => {
      const cInterval = await getClaimInterval();
      const result = (cInterval / 60).toFixed(0);
      setClaimInterval(result);
    };
    const fetchMyStake = async () => {
      const uStake = await myStakes();
      const result = (uStake / 10 ** 8).toFixed(8);
      setMyStake(result);
    };
    const fetchRewardPerStaker = async () => {
      const rpStaker = await getRewardPerStaker();
      const result = (rpStaker / 10 ** 8).toFixed(8);
      setRewardPerStaker(result);
    };
    const fetchtotalStakers = async () => {
      const tStakers = await totalStakers();
      setTotalStakers(tStakers);
    };
    fetchtotalStaked();
    fetchCurrentAPY();
    fetchClaimInterval();
    fetchMyRewards();
    fetchMyStake();
    fetchRewardPerStaker();
    fetchtotalStakers();
    fetchMyTotalRewardsClaimed();
    fetchTotalRewardsAmount();
    fetchTotalContractBal();
  }, [connectedAccount]);

  const onStakeToken = async () => {
    if (stakeAmount == 0) {
      setAlert("STAKE amount can't be zero.", "red");
      return;
    }

    try {
      // Calling stakeTokens, which handles the entire staking process
      await stakeTokens(stakeAmount);
      setStakeAmount(stakeAmount); // Not sure why you are setting 'stakeAmount' here
      setGlobalState("loading", { show: true, msg: "Transaction submitted" });
      setAlert("Staking successful!", "green");
    } catch (error) {
      setGlobalState("loading", { show: false, msg: "" });
      setAlert("Stake error: " + error.message, "red"); // Display the error message
    }
  };

  // Function to handle the input change for stake amount
  const handleStakeAmountChange = (event) => {
    setStakeAmount(event.target.value); // Update the stake or stakeAmount state
  };

  // Function to handle the unstake action
  const handleUnstakeTokens = async (percentage) => {
    if (unstakeAmount == 0) {
      setAlert("UNSTAKE amount can't be zero.", "red");
      return;
    }
    if (myStake == 0) {
      setAlert("You don't have staking balance.", "red");
      return;
    }

    setGlobalState("loading", {
      show: true,
      msg: "Withdrawing your staked balance...",
    });

    try {
      // Your code for handling the unstake action goes here
      const txt = await unstakeTokens(unstakeAmount);
      setUnstakeAmount(txt);
      setGlobalState("loading", { show: true, msg: "Transaction submitted" });
      setAlert("Unstaking Successful!", "green");
    } catch (error) {
      setGlobalState("loading", { show: false, msg: "" });
      setAlert("Unstaking error...", "red");
      return;
    }
  };
  // Function to handle the input change for stake amount
  const handleUnstakeAmountChange = (event) => {
    setUnstakeAmount(event.target.value); // Update the stake or stakeAmount state
  };

  const calculateUnstakeAmount = (percentage) => {
    if (myStake !== null) {
      const selectedPercentage = parseFloat(percentage) / 100; // Convert user input to a number and then to a decimal
      const unstakeAmount = (myStake * selectedPercentage * 10 ** 8).toFixed(0); // Convert to the desired format and set the number of decimal places to 0
      setUnstakeAmount(unstakeAmount);
    }
  };

  // Function to calculate rewards
  const handleRCalculator = async () => {
    if (rCalculatorAmount <= 0) {
      setAlert("Please input amount to CALCULATE.", "red");
      return;
    }
    if (totalStakes == 0 || totalRewardAmount == 0) {
      setAlert("Please try again in few minutes.", "red");
      return;
    }
    try {
      const compute = await Rcalculator(rCalculatorAmount);
      const result = (compute / 10 ** 8).toFixed(8);
      if (result == 0) {
        setAlert("Amount is too low to CALCULATE.", "red");
        return;
      } else {
        setRCalculatorResult(result); // Set the result in state
        setAlert("Calculation done!", "green");
      }
    } catch (error) {
      setGlobalState("loading", { show: false, msg: "" });
      setAlert("Calculation error...", "red");
      console.error(error);
    }
  };

  const handleRCalculatorAmountChange = (event) => {
    setRCalculatorAmount(event.target.value);
  };

  // Function to compound rewards
  const handleCompoundRewards = async () => {
    if (userRewards <= 0) {
      setAlert("No reward balance to COMPOUND.", "red");
      return;
    }

    setGlobalState("loading", {
      show: true,
      msg: "Compounding your staking rewards now...",
    });
    try {
      // Your code for handling the compound rewards action goes here
      const compound = await compoundRewards();
      setCompoundAmount(compound); // Reset the unstake amount input field
      setGlobalState("loading", { show: true, msg: "Transaction submitted" });
      setAlert("Compounding rewards successful!", "green");
    } catch (error) {
      setGlobalState("loading", { show: false, msg: "" });
      setAlert("Compound error...", "red");
      return;
    }
  };

  // Function to claim rewards
  const handleClaimRewards = async () => {
    if (userRewards <= 0) {
      setAlert("No reward balance to CLAIM.", "red");
      return;
    }

    setGlobalState("loading", {
      show: true,
      msg: "Claiming your staking rewards now..",
    });
    try {
      // Your code for handling the claim rewards action goes here
      const claim = await claimRewards();
      setMyRewards(claim); // Reset the userRewards
      setGlobalState("loading", { show: true, msg: "Transaction submitted" });
      setAlert("Claiming rewards successful!", "green");
    } catch (error) {
      setGlobalState("loading", { show: false, msg: "" });
      setAlert("Claiming error...", "red");
    }
  };
  return (
    <div
      className="w-5/6 flex-col flex justify-center items-center mx-auto py-10"
      id="stakeSection"
    >
      <div className="flex flex-col justify-center items-center">
        <img
          className="cursor-pointer rounded-full bg-white"
          src={HpcStakinglogo}
          alt="Hpc Staking Logo"
          style={{ width: "100px", height: "100px" }}
        />
        <label className="text-[#FDBD01] justify-center flex text-3xl font-bold">
          HAPPYCOIN<span className="text-white">&nbsp;Staking</span>
        </label>
        <br />
        <div className=" text-[#1589FF] flex justify-center justify-between items-center">
          {connectedAccount ? null : (
            <span style={{ color: "#00FF00", animation: "blink 4s infinite" }}>
              {"Connect your metamask wallet to stake."}
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <img
          className="cursor-pointer"
          src={Hpclogo}
          alt="HappyCoin Logo"
          style={{ width: "80px", height: "80px" }}
        />
        <span className="text-[#FDBD01] text-2xl ml-0 cursor-pointer font-semibold">
          HAPPYCOIN&nbsp;
          <span className="text-white">
            (<span className="text-[#FDBD01]">HPC</span>)
          </span>
        </span>
        <span className="text-[#00FF00]">Live Statistics:</span>
      </div>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <ul className="text-white text-medium font-semibold flex-center md:flex list-none items-center">
        <li>
          <label className="text-[#FDBD01]">
            APY
            <br />
            <span className="text-white items-center justify-center flex text-xl">
              {connectedAccount ? apy : "0.00"}%
            </span>
          </label>
        </li>
        &nbsp;&nbsp;
        <li>
          <label className="text-[#FDBD01]">
            STAKERS
            <br />
            <span className="text-white items-center justify-center flex text-xl">
              {connectedAccount ? totalStaker : "0"}
            </span>
          </label>
        </li>
        &nbsp;&nbsp;
        <li>
          <label className="text-[#FDBD01]">
            TOTAL BALANCE
            <br />
            <span className="text-white items-center justify-center flex text-xl">
              {connectedAccount ? totalCB : "0.0000000"}
            </span>
          </label>
        </li>
        &nbsp;&nbsp;
        <li>
          <label className="text-[#FDBD01]">
            STAKING
            <br />
            <span className="text-white items-center justify-center flex text-xl">
              {connectedAccount ? totalStakes : "0.0000000"}
            </span>
          </label>
        </li>
        &nbsp;&nbsp;
        <li>
          <label className="text-[#FDBD01]">
            REWARDS
            <br />
            <span className="text-white items-center justify-center flex text-xl">
              {connectedAccount ? totalRewardAmount : "0.00000000"}
            </span>
          </label>
        </li>
        &nbsp;&nbsp;
        <li>
          <label className="text-[#FDBD01]">
            MY STAKES
            <br />
            <span className="text-white items-center justify-center flex text-xl">
              {connectedAccount ? myStake : "0.00000000"}
            </span>
          </label>
        </li>
        &nbsp;&nbsp;
        <li>
          <label className="text-[#FDBD01]">
            MY REWARDS
            <br />
            <span className="text-white  items-center justify-center flex text-xl">
              {connectedAccount ? userRewards : "0.00000000"}
            </span>
          </label>
        </li>
        &nbsp;&nbsp;
        <li>
          <label className="text-[#FDBD01]">
            MY CLAIMED REWARDS
            <br />
            <span className="text-white  items-center justify-center flex text-xl">
              {connectedAccount ? myTotalRewardClaimed : "0.00000000"}
            </span>
          </label>
        </li>
        &nbsp;&nbsp;
        <li>
          <label className="text-[#FDBD01]">
            CURRENT EPOCH REWARD
            <br />
            <span className="text-white items-center justify-center flex text-xl">
              {connectedAccount ? rewardPerStaker : "0.00000000"}
            </span>
          </label>
        </li>
      </ul>
      &nbsp;
      <div>
        <ul className="text-white text-medium font-semibold flex-center md:flex list-none items-center">
          <label className="text-white mr-2 items-center justify-center flex">
            YOU ARE STAKING:&nbsp;
          </label>
          <label className="text-white mr-2 items-center justify-center flex py-2">
            <li>
              <input
                placeholder="1 HPC = 100000000"
                type="number"
                min="1"
                value={stakeAmount}
                onChange={handleStakeAmountChange}
                className="bg-gray-200 text-black rounded py-1 px-2 flex"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </li>
          </label>
          <label className="text-white mr-2 items-center justify-center flex">
            <li>
              {connectedAccount ? (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-[#FDBD01] hover:bg-white md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={onStakeToken}
                >
                  STAKE
                </button>
              ) : (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-[#FDBD01] hover:bg-white md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={connectWallet}
                >
                  STAKE
                </button>
              )}
            </li>
            &nbsp;
            <li>
              {connectedAccount ? (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-[#FDBD01] hover:bg-white md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={handleClaimRewards}
                >
                  CLAIM
                </button>
              ) : (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-[#FDBD01] hover:bg-white md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={connectWallet}
                >
                  CLAIM
                </button>
              )}
            </li>
            &nbsp;
            <li>
              {connectedAccount ? (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-[#FDBD01] hover:bg-white md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={handleCompoundRewards}
                >
                  COMPOUND
                </button>
              ) : (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-[#FDBD01] hover:bg-white md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={connectWallet}
                >
                  COMPOUND
                </button>
              )}
            </li>
          </label>
        </ul>
        <br />
        <ul className="text-white text-medium font-semibold flex-center md:flex list-none items-center">
          <label className="text-white mr-2 items-center justify-center flex">
            YOU ARE UNSTAKING:
          </label>
          <label className="text-white mr-2 items-center justify-center flex py-2">
            <li>
              <input
                placeholder="1 HPC = 100000000"
                type="number"
                min="1"
                value={unstakeAmount}
                onChange={handleUnstakeAmountChange}
                className="bg-gray-200 text-black rounded py-1 px-2 flex"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </li>
          </label>
          <label className="text-white mr-2 items-center justify-center flex">
            <li>
              {connectedAccount ? (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={() => calculateUnstakeAmount(25)}
                >
                  25%
                </button>
              ) : (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={connectWallet}
                >
                  25%
                </button>
              )}
              &nbsp;
              {connectedAccount ? (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={() => calculateUnstakeAmount(50)}
                >
                  50%
                </button>
              ) : (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={connectWallet}
                >
                  50%
                </button>
              )}
              &nbsp;
              {connectedAccount ? (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={() => calculateUnstakeAmount(75)}
                >
                  75%
                </button>
              ) : (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={connectWallet}
                >
                  75%
                </button>
              )}
              &nbsp;
              {connectedAccount ? (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={() => calculateUnstakeAmount(100)}
                >
                  100%
                </button>
              ) : (
                <button className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100">
                  100%
                </button>
              )}
              &nbsp;
              {connectedAccount ? (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-[#FDBD01] hover:bg-white md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={handleUnstakeTokens}
                >
                  UNSTAKE
                </button>
              ) : (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-[#FDBD01] hover:bg-white md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                  onClick={connectWallet}
                >
                  UNSTAKE
                </button>
              )}
            </li>
          </label>
        </ul>
        <br />
        <ul className="text-white text-medium font-semibold flex-center md:flex list-none items-center">
          <label className="text-white mr-2 items-center justify-center flex">
            REWARD CALCULATOR:
          </label>
          <label className="text-white mr-2 items-center justify-center flex py-2">
            <li>
              <input
                placeholder="Input any amount to calculate."
                type="number"
                min="100000000"
                value={rCalculatorAmount}
                onChange={handleRCalculatorAmountChange}
                className="bg-gray-200 text-black rounded py-1 px-2 flex"
                style={{ flex: 1, width: "280px" }}
              />
            </li>
          </label>
          <label className="text-white mr-2 items-center justify-center flex">
            &nbsp;=&nbsp;
            <label className="text-xl">
              {connectedAccount ? rCalculatorResult : "0.00000000"}
            </label>
            &nbsp;
            {connectedAccount ? (
              <button
                className="shadow-l hover:shadow-[#FDBD01] text-black bg-[#FDBD01] hover:bg-white md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                onClick={handleRCalculator}
              >
                CALCULATE POTENTIAL REWARDS
              </button>
            ) : (
              <button
                className="shadow-l hover:shadow-[#FDBD01] text-black bg-[#FDBD01] hover:bg-white md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
                onClick={connectWallet}
              >
                CALCULATE POTENTIAL REWARD
              </button>
            )}
          </label>
        </ul>
        <div className="text-white flex justify-center my-2 p-2 text-center">
          <p>
            ✅ Reward claiming intervals every&nbsp;
            {connectedAccount ? claimInterval : "5"}&nbsp;minutes.
            <br />✅ Staking rewards exclusively comes from HPC Token
            transaction mining. Happy staking!😊
          </p>
        </div>
        <p className="text-white text-center my-3">
          Staking Contract Address:
          <br />
          <a
            href="https://bscscan.com/address/0x9b6F045C27323b9152f473B2Cb99ED2d53C72c42"
            target="_blank"
            alt="RewardsFaucet Contract Address"
            rel="noopener noreferrer"
          >
            <span className="text-[#1589FF]">
              0x9b6F045C27323b9152f473B2Cb99ED2d53C72c42
            </span>
          </a>
          <br />
          Network: Binance Smart Chain(BNB)
        </p>
      </div>
    </div>
  );
};

export default HpcStakingHero;
