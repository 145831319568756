import React, { useEffect, useState } from "react";
import Hpclogo from "../assets/Hpclogo.png";
import { connectWallet, isWalletConnected, disconnectWallet } from "../Mint";
import { truncate, useGlobalState } from "../store";

const Header = () => {
  function refreshPage() {
    window.location.reload();
  }
  const [connectedAccount] = useGlobalState("connectedAccount");
  const [isMenuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  useEffect(() => {
    // Add an event listener to close the menu when clicking outside of it
    const closeMenu = (e) => {
      if (isMenuActive && e.target.closest(".menu-list") === null) {
        setMenuActive(false);
      }
    };

    document.addEventListener("click", closeMenu);

    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, [isMenuActive]);

  useEffect(() => {
    const checkWalletConnection = async () => {
      const walletConnected = await isWalletConnected();
      if (walletConnected) {
        return true;
      } else {
        return false;
      }
    };
    checkWalletConnection();
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div class="min-h-full">
      <nav className="w-full flex md:justify-center justify-between items-center mx-auto fixed top-0 bg-[#131835]">
        <div className="flex flex-column justify-start items-center md:flex-[0.5] flex-initial">
          <img
            className="w-12 h-12 cursor-pointer"
            src={Hpclogo}
            alt="HappyCoin Logo"
            onClick={refreshPage}
          />
          <span
            className="text-[#FDBD01] text-xl ml-0 cursor-pointer font-semibold  transition-all duration-75 delay-100"
            onClick={refreshPage}
          >
            HAPPYCOIN
          </span>
        </div>
        <div>
          {/* Floating Menu Button */}
          <div className="absolute top-0 right-0 m-4 md:hidden">
            <button
              className="text-white flex items-center hover:text-[#FDBD01] transition-all duration-75 delay-100"
              onClick={toggleMenu}
            >
              <span className={`menu-label ${isMenuActive ? "active" : ""}`}>
                MENU
              </span>
              <div className={`hamburger ${isMenuActive ? "active" : ""}`}>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </button>
          </div>
          <ul
            className={`md:flex-[1] text-white text-medium font-semibold md:flex list-none justify-between items-center flex-initial menu-list ${
              isMenuActive ? "active" : ""
            }`}
          >
            <li
              className="text-white  mx-4 cursor-pointer hover:text-[#FDBD01] transition-all duration-75 delay-100"
              onClick={() => scrollToSection("AirdropSection")}
            >
              <a
                href="https://twitter.com/0xHappyCoin/status/1740842898617479224"
                target="_blank"
                alt="Airdrops Announcements"
                rel="noopener noreferrer"
              >
                Free&nbsp;
                <span className="text-[#FDBD01] hover:text-white">HPC</span>
                &nbsp;
                <span className="text-[#00FF00] hover:text-white">
                  Airdrops!
                </span>
              </a>
            </li>
            <li
              className="mx-4 cursor-pointer hover:text-[#FDBD01] transition-all duration-75 delay-100"
              onClick={() => scrollToSection("mintSection")}
            >
              Mint{" "}
              <span className="text-[#00FF00] hover:text-white">Drops!</span>
            </li>
            <li
              className="mx-4 cursor-pointer hover:text-[#FDBD01] transition-all duration-75 delay-100"
              onClick={() => scrollToSection("stakeSection")}
            >
              Stake <span className="text-[#FDBD01] hover:text-white">HPC</span>
            </li>
            <li className="mx-4 cursor-pointer hover:text-[#FDBD01] transition-all duration-75 delay-100">
              <span onClick={() => scrollToSection("ecosystemSection")}>
                Ecosystem
              </span>
            </li>
            <li className="mx-4 cursor-pointer hover:text-[#FDBD01] transition-all duration-75 delay-100">
              <span onClick={() => scrollToSection("milestones")}>Roadmap</span>
            </li>
            <li className="mx-4 cursor-pointer hover:text-[#FDBD01] transition-all duration-75 delay-100">
              <span onClick={() => scrollToSection("ourteam")}>About Us</span>
            </li>
            <li className="mx-4 cursor-pointer hover:text-[#FDBD01] transition-all duration-75 delay-100">
              <span onClick={() => scrollToSection("partnersSection")}>
                As Seen On
              </span>
            </li>
            <li className="mx-4 cursor-pointer hover:text-[#FDBD01] transition-all duration-75 delay-100">
              {connectedAccount ? (
                <button
                  className="shadow-l text-black bg-white hover:bg-[#FDBD01] md:text-xs p-2 rounded-full cursor-pointer transition-all duration-75 delay-100"
                  onClick={disconnectWallet}
                >
                  {truncate(connectedAccount, 5, 5, 15)}
                </button>
              ) : (
                <button
                  className="shadow-l hover:shadow-[#FDBD01] text-black bg-[#FDBD01] hover:bg-white md:text-xs p-2 rounded-full cursor-pointer transition-all duration-75 delay-100"
                  onClick={connectWallet}
                >
                  Connect Wallet
                </button>
              )}
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
