import { TwitterTimelineEmbed } from "react-twitter-embed";

const CommunityAndEvents = () => {
  return (
    <div
      className="w-4/6 flex justify-center items-center mx-auto py-10"
      id="communitySection"
    >
      <div className="equal-column flex flex-col justify-center items-center my-4">
        <p className="text-white flex justify-center items-center font-bold text-3xl hover:text-[#FDBD01] transition-all duration-75 delay-100">
          COMMUNITY / EVENTS
        </p>
        <span className="text-[#FDBD01] text-sm flex justify-center items-center p-1 hover:text-white transition-all duration-75 delay-100">
          Join us on our journey.
        </span>
        <br />
        <div className="grid-container flex">
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="0xHappyCoin"
            data-theme="dark"
            options={{ height: 350, width: 350 }}
          />
        </div>
      </div>
    </div>
  );
};

export default CommunityAndEvents;
