import React, { useEffect, useState } from "react";

const Announcementshero = () => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Calculate the remaining time until the target date
  const calculateCountdown = () => {
    const targetDate = new Date("2023-09-28"); // Replace with your target date
    const now = new Date();

    const timeRemaining = Math.max(0, targetDate - now);

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
    const seconds = Math.floor((timeRemaining / 1000) % 60);

    setCountdown({ days, hours, minutes, seconds });
  };

  useEffect(() => {
    const timer = setInterval(calculateCountdown, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div
      className="w-full flex flex-col items-center py-10 my-4"
      id="announcementSection"
    >
      <p className="text-white text-2xl font-bold hover:text-white transition-all duration-75 delay-100">
        🎯ANNOUNCEMENTS⚡️
      </p>
      <br />
      <p className="text-[#FDBD01] text-2xl font-bold hover:text-white transition-all duration-75 delay-100">
        OFFICIAL TOKEN LAUNCH
        <p className="text-[#FDBD01] text-xl text-center">
          (<span style={{ color: "#00FF00" }}>TBA</span>)
        </p>
      </p>
      <button>
        <p className="text-white text-xl hover:text-[#FDBD01] ">
          &nbsp;{countdown.days}d:&nbsp;{countdown.hours}h:&nbsp;
          {countdown.minutes}m:&nbsp;
          {countdown.seconds}s&nbsp;
        </p>
      </button>
      <br />
      <p className="text-[#FDBD01] text-xl font-bold hover:text-white transition-all duration-75 delay-100">
        TOKEN LAUNCH WAITLIST (
        <span style={{ color: "#00FF00", animation: "blink 4s infinite" }}>
          Active...
        </span>
        )
      </p>
      <a
        class="e-widget"
        href="https://tinyurl.com/yv6k2e69"
        target="_blank"
        alt="Airdrops campaign"
        rel="noopener noreferrer"
      >
        <button
          className="shadow-l hover:shadow-[#FDBD01] flex
            justify-center items-center  rounded
          bg-white cursor-pointer text-black my-4 md:text-l p-2
            hover:bg-[#FDBD01] hover:text-black transition-all
            duration-75 delay-100 "
          onClick={""}
        >
          Click here to fill the WAITLIST form.
        </button>
      </a>
    </div>
  );
};

export default Announcementshero;
