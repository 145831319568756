import abi from "./abis/src/contracts/Adulam.sol/RewardsFaucetv1Abi.json";
import address from "./abis/rewardsFaucetv1.json";
import { getGlobalState, setGlobalState } from "./store";
import { ethers } from "ethers";

const { ethereum } = window;
const contractAddress = address.address;
const contractAbi = abi.abi;

const getEthereumContract = () => {
  const connectedAccount = getGlobalState("connectedAccount");

  if (connectedAccount) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAddress, contractAbi, signer);

    return contract;
  } else {
    return getGlobalState("contract");
  }
};

const connectWallet = async () => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    setGlobalState("connectedAccount", accounts[0]);
  } catch (error) {
    reportError(error);
  }
};

const getVaultBalance = async () => {
  try {
    const contract = getEthereumContract();
    const vaultBalance = await contract.forShareOut();
    return vaultBalance.toString();
  } catch (error) {
    reportError(error);
  }
};

const getRewards = async () => {
  try {
    const contract = getEthereumContract();
    const myRewards = await contract.getMyRewards();
    return myRewards.toString();
  } catch (error) {
    reportError(error);
  }
};

const claimReward = async (amount) => {
  try {
    const contract = getEthereumContract();
    await contract.claimRewards(amount);
  } catch (error) {
    reportError(error);
  }
};
const totalCardHolders = async () => {
  try {
    const contract = getEthereumContract();
    const myRewards = await contract.totalCardholders();
    return myRewards.toString();
  } catch (error) {
    reportError(error);
  }
};
const totalRewardClaimed = async () => {
  try {
    const contract = getEthereumContract();
    const result = await contract.getTotalRewardsClaimed();
    return result.toString();
  } catch (error) {
    reportError(error);
  }
};
const gettimeElapsed = async () => {
  try {
    const contract = getEthereumContract();
    const tElapsed = await contract.getTimeElapsed();
    return tElapsed.toString();
  } catch (error) {
    reportError(error);
  }
};
const getCountDown = async () => {
  try {
    const contract = getEthereumContract();
    const remainingTime = await contract.getRemainingTimeToClaim();
    return remainingTime.toString();
  } catch (error) {
    reportError(error);
  }
};

const getRewardSymbol = async () => {
  try {
    const contract = getEthereumContract();
    const RSymbol = await contract.getRewardTokenSymbol();
    return RSymbol.toString();
  } catch (error) {
    reportError(error);
  }
};

const getRewardName = async () => {
  try {
    const contract = getEthereumContract();
    const RName = await contract.getRewardTokenName();
    return RName.toString();
  } catch (error) {
    reportError(error);
  }
};
const getRewardBalance = async () => {
  try {
    const contract = getEthereumContract();
    const RName = await contract.myRewardBalance();
    return RName.toString();
  } catch (error) {
    reportError(error);
  }
};

const reportError = (error) => {
  console.log(error.message);
  throw new Error("No ethereum object.");
};
export {
  getEthereumContract,
  connectWallet,
  getVaultBalance,
  getRewards,
  claimReward,
  totalCardHolders,
  totalRewardClaimed,
  gettimeElapsed,
  getCountDown,
  getRewardSymbol,
  getRewardName,
  getRewardBalance,
};
