import React, { useEffect, useState } from "react";
import { setAlert, setGlobalState, useGlobalState } from "../store";
import HPCCARD from "../assets/HPCCARD.png";
import {
  connectWallet,
  getMaxSupply,
  getMintFee,
  getTotalSupply,
  mint,
  getName,
} from "../Mint1";

const mintHero1 = () => {
  const [connectedAccount] = useGlobalState("connectedAccount");
  const [maxSupply, setMaxSupply] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [mintFee, setMintFee] = useState("");
  const [Amount, setTotalMinted] = useState(1);
  const [txHash, setTxHash] = useState("");
  const [name, setName] = useState("");
  const [referrerAddress, setReferrerAddress] = useState("");

  useEffect(() => {
    const fetchMaxSupply = async () => {
      const result = await getMaxSupply();
      setMaxSupply(result);
    };
    const fetchTotalSupply = async () => {
      const totalMinted = await getTotalSupply();
      setTotalSupply(totalMinted);
    };
    const fetchMintFee = async () => {
      const result = await getMintFee();
      const formattedMintFee = (result / 10 ** 18).toFixed(2);
      setMintFee(formattedMintFee);
    };
    const fetchName = async () => {
      const result = await getName();
      setName(result);
    };
    fetchName();
    fetchMaxSupply();
    fetchTotalSupply();
    fetchMintFee();
  }, [connectedAccount]);

  const onMintNFT = async () => {
    if (!window.ethereum || !window.ethereum.selectedAddress) {
      setGlobalState("loading", { show: false, msg: "" });
      setAlert("Please connect to mint card.", "red");
      return;
    }
    if (referrerAddress <= 0) {
      setAlert("Please input who invited you to mint.", "red");
      return;
    }

    if (Amount <= 0) {
      setAlert("Invalid number of Card.", "red");
      return;
    }

    setGlobalState("loading", {
      show: true,
      msg: "Minting HappyCoin Card to your account",
    });

    try {
      // Use the addressParam as the referrer address
      const tx = await mint(Amount, referrerAddress);
      setTxHash(tx);
      setGlobalState("loading", { show: false, msg: "" });
      setAlert("Transaction submitted successfully.", "green");
    } catch (error) {
      setGlobalState("loading", { show: false, msg: "" });
      setAlert("Insufficient BNB Balance!", "red");
      return;
    }
  };

  return (
    <div>
      <div
        className="w-4/6 flex justify-center items-center mx-auto py-10"
        id="mintSection"
      >
        <div className="flex flex-col justify-center items-center my-4">
          <h1 className="text-white text-4xl font-bold text-center ">
            Mint Drops (
            <span style={{ color: "#00FF00", animation: "blink 3s infinite" }}>
              Live...
            </span>
            )
            <br />
            <span className="text-[#FDBD01] ">{"HAPPYCOIN CARD"}</span>
          </h1>

          <p className=" flex text-center text-[#FDBD01] font-semibold mt-3">
            "The Exclusive Premium Privilege Card in Our Ecosystem"
          </p>
          <br />
          <div className="flex justify-center items-center">
            <a
              href="https://opensea.io/collection/happycoincard"
              target="_blank"
              alt="Opensea collection"
              rel="noopener noreferrer"
            >
              <img
                className="rounded shadow-xl shadow-white hover:shadow-[#FDBD01] cursor-pointer transition-all duration-75 delay-100"
                src={HPCCARD}
                alt="Connect to view the Card"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </a>
          </div>

          <br />

          <p className="text-white text-center">
            Mint Price:&nbsp;{connectedAccount ? mintFee : "0.00"}&nbsp;BNB
          </p>

          <div
            className="flex flex-col justify-center items-center shadow-x shadow-black text-black
            bg-[#FDBD01]  p-1  hover:bg-white hover:text-black hover:shadow-[#FDBD01]
           rounded-full cursor-pointer my-2"
          >
            <span className=" text-sm">
              {connectedAccount ? totalSupply : "0"} /
              {connectedAccount ? maxSupply : "10000"}
            </span>
          </div>
          <div className="flex flex-col p-1 justify-center text-white">
            <p>
              Number of Card:
              <input
                className="text-black item-center justify-center h-6 ml-1 rounded shadow-black cursor-pointer
                shadow-l hover:shadow-[#FDBD01] "
                type="number"
                min="1"
                value={Amount}
                onChange={(e) => setTotalMinted(e.target.value)}
                style={{
                  width: "70px",
                }}
              />
            </p>
          </div>
          <br />
          <div className=" text-[#1589FF] flex justify-center justify-between items-center">
            {connectedAccount ? null : (
              <span
                style={{ color: "#00FF00", animation: "blink 4s infinite" }}
              >
                {"Connect your metamask wallet to mint cards."}
              </span>
            )}
          </div>

          <div className="flex flex-col justify-center items-center shadow-black">
            {connectedAccount ? (
              <button
                className="shadow-l hover:shadow-[#FDBD01] flex flex-col
            justify-center items-center  rounded
          bg-[#FDBD01] cursor-pointer text-black my-4 md:text-l p-2
            hover:bg-white hover:text-black transition-all
            duration-75 delay-100"
                onClick={onMintNFT}
              >
                MINT MY CARD NOW
              </button>
            ) : (
              <button
                className="shadow-l hover:shadow-[#FDBD01] flex flex-col
            justify-center items-center  rounded
          bg-[#FDBD01] cursor-pointer text-black my-4 md:text-l p-2
            hover:bg-white hover:text-black transition-all
            duration-75 delay-100"
                onClick={connectWallet}
              >
                MINT MY CARD NOW
              </button>
            )}
            <br />
            {/* Place the "Paste Ethereum Address" input field below the button */}
            <div className="flex flex-col justify-center items-center shadow-black">
              <div className="mt-2">
                <div className="text-center">
                  <p className="text-[#1589FF] mr-2">
                    ✅ Please provide who invited you to mint.
                  </p>
                  <input
                    type="text"
                    placeholder="His/her wallet address."
                    value={referrerAddress}
                    onChange={(e) => setReferrerAddress(e.target.value)}
                    className="bg-gray-200 text-black rounded py-1 px-2"
                    style={{ flex: 1 }}
                  />
                </div>
              </div>
            </div>
            <br />
            <p className="text-white text-center">
              <span className="text-[#FDBD01] font-bold">
                Mint Fee Distribution:
              </span>
              <br />
              ✅ Referral Reward 10% <br />
              ✅ Management Fee 45% <br />
              ✅ Cardholders Reward 20% <br />
              ✅ Liquidity Pool 20% <br />
              ✅ Charity Fund 5% <br />
            </p>
          </div>
          <br />
          <p className="text-white text-center">
            HAPPYCOIN CARD Contract Address:
            <br />
            <a
              href="https://bscscan.com/token/0x66f396BD0c58C98f3D08EB46A1B819D4F1D020fD"
              target="_blank"
              alt="HappyCoin Card Contract Address"
              rel="noopener noreferrer"
            >
              <span className="text-[#1589FF]">
                0x66f396BD0c58C98f3D08EB46A1B819D4F1D020fD
              </span>
            </a>
          </p>
          <p className="text-white text-center">
            Network: Binance Smart Chain(BNB)
          </p>
        </div>
      </div>
    </div>
  );
};

export default mintHero1;
