import founderLogo from "../assets/founder.jpg";
import twitter from "../assets/twitter_icon.png";
const Aboutus = () => {
  return (
    <div
      className="w-4/6 flex justify-center items-center mx-auto py-10"
      id="ourteam"
    >
      <div className="equal-column flex flex-col justify-center items-center my-4">
        <p className=" text-white flex justify-center items-center font-bold text-3xl hover:text-[#FDBD01] transition-all duration-75 delay-100">
          ABOUT US
        </p>
        <span className=" text-[#FDBD01] text-sm flex justify-center items-center hover:text-white transition-all duration-75 delay-100">
          A group with a vision.
        </span>
        <br />
        <p className="text-white">
          The HAPPYCOIN Management Team is comprised of a small group of
          individuals united by a common goals and vision.
          <br />
          We firmly believe in the transformative potential of blockchain
          technology and its ongoing digital innovations that are
          <br /> sure to pave the way for a brighter future. Our commitment is
          to create a standout ecosystem with a strong emphasis
          <br /> on innovation, security, and social responsibility.
          <br />
          <br />
          Happycoin is more than just a memecoin; we are a start-up project
          focused on innovating DeFi products and services to <br />
          facilitate better crypto adoption for the masses.
          <br />
          <br />
          We invite you to join us on this exciting journey as we reshape the
          future of cryptocurrency through the Happycoin Ecosystem. ❤️
        </p>
      </div>
    </div>
  );
};

export default Aboutus;
