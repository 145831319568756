import Bsclogo from "../assets/BSC.png";
import Pcslogo from "../assets/pancakeswap.png";
import OSlogo from "../assets/opensea.png";
import DVlogo from "../assets/dexview.png";
import CSlogo from "../assets/cslogo.png";
import CBlogo from "../assets/CBLogo.png";
import BOTOlogo from "../assets/botoheader.png";
import NFTCal from "../assets/nftcalendar.png";

const asseenas = () => {
  return (
    <div
      className="w-full flex justify-center items-center mx-auto py-10"
      id="partnersSection"
    >
      <div className="equal-column flex flex-col justify-center items-center my-4">
        <p className=" text-white flex justify-center items-center font-bold text-3xl hover:text-[#FDBD01] transition-all duration-75 delay-100">
          AS SEEN ON
        </p>
        <span className=" text-[#FDBD01] text-sm flex justify-center items-center hover:text-white transition-all duration-75 delay-100">
          Platforms that we're in.
        </span>
        <br />
        <div className="w-full flex justify-center justify-between">
          <div className="equal-column flex flex-col justify-center  items-center "></div>
          <div className="equal-column flex flex-col justify-center  items-center ">
            <a
              href="https://www.dexview.com/bsc/0x57F532ecFD238241E023e2067d92e51a3361fa55"
              target="_blank"
              alt="Dexview"
              rel="noopener noreferrer"
            >
              <img
                className="cursor-pointer rounded"
                src={DVlogo}
                alt="Dexview Logo"
                style={{ width: "150px", height: "50px" }}
              />
            </a>
          </div>
          <div className="equal-column flex flex-col justify-center items-center ">
            <a
              href="https://bscscan.com/token/0x57f532ecfd238241e023e2067d92e51a3361fa55"
              target="_blank"
              alt="BscScan"
              rel="noopener noreferrer"
            >
              <img
                className="cursor-pointer rounded"
                src={Bsclogo}
                alt="BscScan Logo"
                style={{ width: "150px", height: "100px" }}
              />
            </a>
          </div>
          <div className="equal-column flex flex-col justify-center  items-center ">
            <a
              href="https://pancakeswap.finance/swap?outputCurrency=0x57F532ecFD238241E023e2067d92e51a3361fa55&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
              target="_blank"
              alt="PancakeSwap"
              rel="noopener noreferrer"
            >
              <img
                className="cursor-pointer rounded"
                src={Pcslogo}
                alt="PancakeSwap Logo"
                style={{ width: "150px", height: "35px" }}
              />
            </a>
          </div>
          <div className="equal-column flex flex-col justify-center  items-center ">
            <a
              href="https://opensea.io/collection/happycoincard"
              target="_blank"
              alt="OpenSea"
              rel="noopener noreferrer"
            >
              <img
                className="cursor-pointer rounded"
                src={OSlogo}
                alt="PancakeSwap Logo"
                style={{ width: "150px", height: "35px" }}
              />
            </a>
          </div>
          <div className="equal-column flex flex-col justify-center  items-center ">
            <a
              href="https://coinsniper.net/coin/51070"
              target="_blank"
              alt="CoinSniper"
              rel="noopener noreferrer"
            >
              <img
                className="cursor-pointer rounded"
                src={CSlogo}
                alt="PancakeSwap Logo"
                style={{ width: "150px", height: "35px" }}
              />
            </a>
          </div>
          <div className="equal-column flex flex-col justify-center  items-center "></div>
        </div>
        <br />
        <div className="w-full flex justify-center justify-between">
          <div className="equal-column flex flex-col justify-center  items-center "></div>
          <div className="equal-column flex flex-col justify-center  items-center ">
            <a
              href="https://coinbrain.com/coins/bnb-0x57f532ecfd238241e023e2067d92e51a3361fa55"
              target="_blank"
              alt="CoinBrain"
              rel="noopener noreferrer"
            >
              <img
                className="cursor-pointer rounded bg-white"
                src={CBlogo}
                alt="CoinBrain Logo"
                style={{ width: "150px", height: "35px" }}
              />
            </a>
          </div>
          <div className="equal-column flex flex-col justify-center items-center ">
            <a
              href="https://boto.io/creator/HAPPYCOIN/"
              target="_blank"
              alt="Boto"
              rel="noopener noreferrer"
            >
              <img
                className="cursor-pointer rounded bg-white"
                src={BOTOlogo}
                alt="Boto Logo"
                style={{ width: "150px", height: "35px" }}
              />
            </a>
          </div>
          <div className="equal-column flex flex-col justify-center  items-center ">
            <a
              href="https://nftcalendar.io/event/happycoin-card/"
              target="_blank"
              alt="Boto"
              rel="noopener noreferrer"
            >
              <img
                className="cursor-pointer rounded bg-white"
                src={NFTCal}
                alt="Boto Logo"
                style={{ width: "150px", height: "35px" }}
              />
            </a>
          </div>
          <div className="equal-column flex flex-col justify-center  items-center "></div>
          <div className="equal-column flex flex-col justify-center  items-center "></div>
          <div className="equal-column flex flex-col justify-center  items-center "></div>
        </div>
      </div>
    </div>
  );
};

export default asseenas;
