import Hpclogo from "../assets/Hpclogo.png";
import Faucetlogo from "../assets/Faucet.png";
import HPCCARD from "../assets/HPCCARD.png";
import HPCStaking from "../assets/hpcstaking.png";

const Ecosystemhero = () => {
  return (
    <div
      className="w-5/6 flex-col flex justify-center items-center mx-auto py-10"
      id="ecosystemSection"
    >
      <div className="flex flex-col justify-center items-center my-4">
        <p className=" text-white flex justify-center items-center font-bold text-3xl hover:text-[#FDBD01] transition-all duration-75 delay-100">
          ECOSYSTEM
        </p>
        <span className="text-[#FDBD01] text-sm flex items-center justify-center p-1 hover:text-white transition-all duration-75 delay-100">
          Token Utilities, Products, and Services.
        </span>
        <br />
        <br />
        <div className="flex-col flex justify-center justify-between items-center mx-auto">
          <div className="flex justify-center items-center my-2">
            <img
              className="cursor-pointer rounded-full bg-white"
              src={Hpclogo}
              alt="HPC Logo"
              style={{ width: "70px", height: "70px" }}
            />
          </div>
          <p className="text-white px-1 text-xl">
            <span className="text-[#FDBD01]">HAPPYCOIN</span>&nbsp;
            <span className="text-[#FDBD01]">(HPC)</span> Token
          </p>
          <br />
          <p className="text-white px-1">
            HAPPYCOIN HPC Token is a deflationary BEP20 token under Binance
            Smart chain with a limited supply of 100M and fixed transfer fees of
            1.8%. It is designed to serve as the main utility token throughout
            the ecosystem. In addition to being a store of value for long-term
            holders, HAPPYCOIN $HPC also offers unique rewards and incentives to
            its HAPPYCOIN CardHolders.
          </p>
        </div>
        <div className="flex flex-col items-center equal-column">
          <div className="flex justify-center items-center my-4">
            <img
              className="cursor-pointer rounded-full bg-white"
              src={Faucetlogo}
              alt="Faucet Logo"
              style={{ width: "70px", height: "70px" }}
            />
          </div>
          <p className="text-white px-1 text-xl">
            <span className="text-[#FDBD01]">REWARDS</span> Faucet
          </p>
          <br />
          <p className="text-white px-1">
            The REWARDS Faucet is an auto-reward royalty program designed to
            incentivize HAPPYCOIN Cardholders. 27.7% of the HAPPYCOIN $HPC
            transaction fee is automatically directed to the REWARDS faucet,
            which eligible Cardholders can claim at predefined intervals.
          </p>
        </div>
        <div className="flex flex-col items-center equal-column">
          <div className="flex justify-center items-center my-4">
            <img
              className="cursor-pointer rounded-full bg-white"
              src={HPCCARD}
              alt="HAPPYCOIN Card"
              style={{ width: "70px", height: "70px" }}
            />
          </div>
          <p className="text-white px-1 text-xl">
            <span className="text-[#FDBD01]">HAPPYCOIN CARD</span>
          </p>
          <br />
          <p className="text-white px-1">
            The HAPPYCOIN Card is an ERC721 token designed to unlock all current
            and future rewards, benefits, and incentives offered within our
            ecosystem. The HAPPYCOIN Card is a premium privilege card that is
            ownable, transferable, and can be sold on any NFT marketplaces
            operating on the Binance Smart Chain.
          </p>
        </div>
        <div className="flex flex-col items-center equal-column">
          <div className="flex justify-center items-center my-4">
            <img
              className="cursor-pointer rounded-full bg-white"
              src={HPCStaking}
              alt="HAPPYCOIN Staking"
              style={{ width: "70px", height: "70px" }}
            />
          </div>
          <p className="text-white px-1 text-xl">
            <span className="text-[#FDBD01]">HAPPYCOIN STAKING</span>
          </p>
          <br />
          <p className="text-white px-1">
            The HAPPYCOIN Staking program is one of our unique HPC mining
            programs, where stakers have the opportunity to earn additional HPC
            rewards through transaction mining. 0.10% of transfer fees are
            distributed among all active stakers, and stakers can view all the
            details in the staking program section. Holders have the freedom to
            unstake their tokens whenever they desire.
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center my-4 equal-column">
        <span className="text-[#FDBD01] font-semibold flex justify-center items-center p-1 hover:text-white transition-all duration-75 delay-100">
          More programs to come...
        </span>
      </div>
      <br />
    </div>
  );
};

export default Ecosystemhero;
