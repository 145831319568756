import React, { useState, useEffect } from "react";
import { isWalletConnected } from "./Mint";
import { useGlobalState } from "./store";
import Alert from "./components/Alert";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Loading from "./components/Loading";
import Rewardsv1 from "./components/RewardsHerov1";
import Hpc from "./components/Hpchero";
import Ecosystem from "./components/Ecosystemhero";
//import Howtobuyhero from "./components/Howtobuyhero";
import Asseenas from "./components/Asseenas";
import Communityandevents from "./components/Communityandevents";
import Aboutus from "./components/Aboutus";
import AH from "./components/Announcementshero";
//import DH from "./components/Dropshero";
import Nft1 from "./components/Minthero1";
import MS from "./components/Milestones";
import IB from "./components/Investors";
import HpcStaking from "./components/HpcStakingHerov1";
//import "./index.css";

const App = () => {
  const [connectedAccount] = useGlobalState("connectedAccount");

  return (
    <div className="w-full flex-center justify-center justify-between items-center gradient-bg-hero">
      <div className="gradient-bg-hero">
        <div className="p-2">
          <Header />
        </div>
        <div className="content-wrapper flex justify-center items-center">
          <AH />
        </div>
        <div className="content-wrapper flex justify-center items-center">
          <div className="content-column">{<Rewardsv1 />}</div>
          &nbsp;
          <div className="content-column">{<Nft1 />}</div>
          &nbsp;
          <div className="content-column">{<Hpc />}</div>
        </div>
        <div className="content-wrapper flex justify-center items-center">
          <HpcStaking />
        </div>
        <div className="content-wrapper flex justify-center items-center">
          <Ecosystem />
        </div>
        <div className="content-wrapper flex justify-center items-center">
          <MS />
        </div>
        <div className="">
          <Aboutus />
        </div>
        <div className="">
          <IB />
        </div>
        <div className="">
          <Asseenas />
        </div>
        <div className="">
          <Communityandevents />
        </div>
        <div className="content-wrapper flex justify-center items-center">
          <Footer />
          <Loading />
          <Alert />
        </div>
      </div>
    </div>
  );
};

export default App;
