import Hpclogo from "../assets/Hpclogo.png";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../store";
import {
  getMaxSupply,
  getTotalHolders,
  getTotalSupply,
  getTranstFee,
  getBurnFee,
  getDecimal,
  getSymbol,
  getName,
  getTokenBurned,
} from "../Hpc";

const Hpchero = () => {
  const [connectedAccount] = useGlobalState("connectedAccount");
  const [maxSupply, setMaxSupply] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [totalHolder, setTotalHolder] = useState("");
  const [burntFee, setBurntFee] = useState("");
  const [transactionFees, setTransFee] = useState("");
  const [decimals, setDecimal] = useState("");
  const [symbol, setSymbol] = useState("");
  const [name, setName] = useState("");
  const [totalburnt, setTotalBurned] = useState("");

  useEffect(() => {
    const fetchMaxSupply = async () => {
      const max_Supply = await getMaxSupply();
      const result = (max_Supply / 10 ** 8).toFixed(2);
      setMaxSupply(result);
    };
    const fetchTotalSupply = async () => {
      const totalMinted = await getTotalSupply();
      const result = (totalMinted / 10 ** 8).toFixed(2);
      setTotalSupply(result);
    };

    const fetchTotalHolders = async () => {
      const result = await getTotalHolders();
      setTotalHolder(result);
    };
    const fetchBurntFee = async () => {
      const getBurntFee = await getBurnFee();
      const result = (getBurntFee / 10 ** 2).toFixed(2);
      setBurntFee(result);
    };
    const fetchTranstFee = async () => {
      const result = await getTranstFee();
      const tFee = (result / 10 ** 2).toFixed(2);
      setTransFee(tFee);
    };
    const fetchDecimal = async () => {
      const decimal = await getDecimal();
      setDecimal(decimal);
    };

    const fetchSymbol = async () => {
      const symbols = await getSymbol();
      setSymbol(symbols);
    };
    const fetchName = async () => {
      const Name = await getName();
      setName(Name);
    };
    const fetchTotalBurned = async () => {
      const result = await getTokenBurned();
      const totalBurned = (result / 10 ** 8).toFixed(2);
      setTotalBurned(totalBurned);
    };

    fetchMaxSupply();
    fetchTotalSupply();
    fetchTotalHolders();
    fetchBurntFee();
    fetchTranstFee();
    fetchDecimal();
    fetchSymbol();
    fetchName();
    fetchTotalBurned();
  }, [connectedAccount]);

  return (
    <div
      className="w-4/6 flex justify-center items-center mx-auto py-10"
      id="tokenomicsSection"
    >
      <div className="flex flex-col justify-center items-center">
        <img
          className="cursor-pointer rounded-full bg-white"
          src={Hpclogo}
          alt="HPC Logo"
          style={{ width: "100px", height: "100px" }}
        />
        <p className="text-white text-center text-4xl">
          <span className="text-[#FDBD01] font-bold ">{"HAPPYCOIN"}</span>
          <br />
        </p>
        <p className="text-white text-center text-xl">Tokenomics</p>
        <br />
        <p className="text-white text-center">
          Token: {"BEP20"}
          <br />
          Symbol: <span className="text-[#FDBD01]">{"HPC"}</span>
          <br />
          Decimals: {connectedAccount ? decimals : "8"}
          <br />
          Max Supply: {connectedAccount ? maxSupply : "0"}
          <br />
          Circulating Supply: {connectedAccount ? totalSupply : "0"}
          <br />
          Burned Supply: {connectedAccount ? totalburnt : "0"}
          <br />
          Transaction Fee: {"1.8"}%
          <br />
          🔥🔥Burn Rate: {"0.05"}%
          <br />
          🔥Burn Floor: {"0.00"}
          <br />
          Total Holders: {connectedAccount ? totalHolder : "0"}
        </p>
        <br />
        <p className="text-white text-center">
          <span className="text-[#FDBD01] font-bold">Token Allocation:</span>
          <br />
          Token Sale: 30M (30%)
          <br />
          Token L/P: 30M (30%)
          <br />
          CEX Listing: 10M (10%)
          <br />
          Management Team: 25M (25%)
          <br />
          Ecosystem Contributors: 5M (5%)
        </p>
        <br />
        <p className="text-white text-center">
          <span className="text-[#FDBD01] font-bold">
            Transaction Fee Distribution:
          </span>
          <br />
          ✅ Reciever 98.2% <br />
          ✅ Management Fee 0.90% <br />
          ✅ Cardholders Reward 0.50% <br />
          ✅ Staking Reward 0.10% <br />
          ✅ Liquidity Pool 0.20% <br />
          ✅ Charity Fund 0.05% <br />
          ✅ Burnt 0.05% <br />
        </p>
        <br />
        <p className="text-white text-center">
          HAPPYCOIN HPC Token Contract Address:
          <br />
          <a
            href="https://bscscan.com/token/0x57f532ecfd238241e023e2067d92e51a3361fa55"
            target="_blank"
            alt="HappyCoin Contract Address"
            rel="noopener noreferrer"
          >
            <span className="text-[#1589FF]">
              0x57f532ecfd238241e023e2067d92e51a3361fa55
            </span>
          </a>
        </p>
        <p className="text-white text-center">
          Network: Binance Smart Chain(BNB)
        </p>
      </div>
    </div>
  );
};
export default Hpchero;
