const investors = () => {
  return (
    <div
      className="w-5/6 flex justify-center items-center mx-auto py-10"
      id="backers"
    >
      <div className="equal-column flex flex-col justify-center items-center my-4">
        <p className=" text-white flex justify-center items-center font-bold text-3xl hover:text-[#FDBD01] transition-all duration-75 delay-100">
          INVESTORS
        </p>
        <span className=" text-[#FDBD01] text-sm flex justify-center items-center hover:text-white transition-all duration-75 delay-100">
          Our project backers.
        </span>
        <br />
        <p className="text-white flex text-center">
          Do you want to be our project backer?
          <br />
          Email us at support@myhappycoin.com now.
        </p>
      </div>
    </div>
  );
};

export default investors;
