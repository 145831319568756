import abi from "./abis/src/contracts/Adulam.sol/HpcAbi.json";
import address from "./abis/hpcContractAddress.json";
import { getGlobalState, setGlobalState } from "./store";
import { ethers } from "ethers";
const { ethereum } = window;
const contractAddress = address.address;
const contractAbi = abi.abi;

const getEthereumContract = () => {
  const connectedAccount = getGlobalState("connectedAccount");

  if (connectedAccount) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAddress, contractAbi, signer);

    return contract;
  } else {
    return getGlobalState("contract");
  }
};

const isWalletConnected = async () => {
  try {
    if (!ethereum) return alert("Please install Metamask");
    const accounts = await ethereum.request({ method: "eth_accounts" });

    window.ethereum.on("chainChanged", (chainId) => {
      window.location.reload();
    });

    window.ethereum.on("accountsChanged", async () => {
      setGlobalState("connectedAccount", accounts[0]);
      await isWalletConnected();
    });

    if (accounts.length) {
      setGlobalState("connectedAccount", accounts[0]);
    } else {
      alert("Connect your wallet to use this dApp.");
      console.log("No accounts found.");
    }
  } catch (error) {
    reportError(error);
  }
};

const connectWallet = async () => {
  try {
    if (!ethereum) return alert("Please install Metamask");
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    setGlobalState("connectedAccount", accounts[0]);
  } catch (error) {
    reportError(error);
  }
};

const reportError = (error) => {
  console.log(error.message);
  throw new Error("No ethereum object.");
};

const getTotalSupply = async () => {
  try {
    const contract = getEthereumContract();
    const totalSupply = await contract.totalSupply();
    return totalSupply.toString();
  } catch (error) {
    reportError(error);
  }
};

const getMaxSupply = async () => {
  try {
    const contract = getEthereumContract();
    const maxSupply = await contract.getMaxSupply();
    return maxSupply.toString();
  } catch (error) {
    reportError(error);
  }
};

const getTotalHolders = async () => {
  try {
    const contract = getEthereumContract();
    const holders = await contract.totalHolder();
    return holders.toString();
  } catch (error) {
    reportError(error);
  }
};
const getBurnFee = async () => {
  try {
    const contract = getEthereumContract();
    const BurntFee = await contract.tokenBurnFee();
    return BurntFee.toString();
  } catch (error) {
    reportError(error);
  }
};
const getTranstFee = async () => {
  try {
    const contract = getEthereumContract();
    const totalFee = await contract.totalFees();
    return totalFee.toString();
  } catch (error) {
    reportError(error);
  }
};
const getDecimal = async () => {
  try {
    const contract = getEthereumContract();
    const result = await contract.decimals();
    return result.toString();
  } catch (error) {
    reportError(error);
  }
};
const getSymbol = async () => {
  try {
    const contract = getEthereumContract();
    const result = await contract.symbol();
    return result.toString();
  } catch (error) {
    reportError(error);
  }
};
const getName = async () => {
  try {
    const contract = getEthereumContract();
    const result = await contract.name();
    return result.toString();
  } catch (error) {
    reportError(error);
  }
};
const getTokenBurned = async () => {
  try {
    const contract = getEthereumContract();
    const result = await contract.totalBurned();
    return result.toString();
  } catch (error) {
    reportError(error);
  }
};
export {
  isWalletConnected,
  getEthereumContract,
  connectWallet,
  getTotalSupply,
  getMaxSupply,
  getTotalHolders,
  getBurnFee,
  getTranstFee,
  getDecimal,
  getSymbol,
  getName,
  getTokenBurned,
};
