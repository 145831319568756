const milestones = () => {
  return (
    <div
      className="w-5/6 flex-col flex justify-center items-center mx-auto py-10"
      id="milestones"
    >
      <p className="flex justify-center text-white font-bold text-3xl hover:text-[#FDBD01] transition-all duration-75 delay-100">
        PROJECT MILESTONES
      </p>
      <span className="flex justify-center text-[#FDBD01] text-sm hover:text-white transition-all duration-75 delay-100">
        Our roadmap to success.
      </span>
      <br />
      <div className="flex flex-col justify-center items-center my-4">
        <p className=" text-[#00FF00] justify-center items-center font-bold text-2xl hover:text-white transition-all duration-75 delay-100">
          Phase 1
        </p>
        <p className=" text-[#FDBD01] justify-center font-bold text-2xl hover:text-white transition-all duration-75 delay-100">
          🎯 2023 - Q1 🏆
        </p>
        <p className=" text-white text-1xl">
          ✅ Project conceptualization and finalization.
          <br />✅ Initial project development.
        </p>
        <br />
        <p className=" text-[#FDBD01] flex font-bold text-2xl hover:text-white transition-all duration-75 delay-100">
          🎯 2023 - Q2 🏆
        </p>
        <p className=" flex text-white text-1xl">
          ✅ Social media channels created.
          <br />✅ Website launched.
          <br />✅ 3 programs deployed on BSC.
          <br />✅ HPC/BUSD LP Added on Pancakeswap.
          <br />✅ HPC CARD Mint Drops launched.
          <br />✅ HPC CARD Collection created at OpenSea.
          <br />✅ HPC Token verified on DexView.
        </p>
        <br />
        <p className=" text-[#FDBD01] flex font-bold text-2xl hover:text-white transition-all duration-75 delay-100">
          🎯 2023 - Q3-Q4 👷🏻
        </p>
        <p className=" text-white flex">
          ✅ HPC Token listed/verified on CoinSniper.
          <br />✅ HPC Token listed/verified on CoinBrain.
          <br />✅ HPC Project published 1 quest on QuestN.
          <br />✅ 1st Airdrops Campaign (26000 HPC).
          <br />✅ HPCCARD SC Upgraded.
          <br />✅ HPC Staking Program launched.
          <br />✅ Rewards Faucet SC Upgraded.
          <br />✅ Public 1639000 HPC Airdrops(Only 0.03% claimed)
          <br />
          🕢 Official HPC Token Launching.
          <br />
          🕢 HPC/BNB Official listing on Pancakeswap.
          <br />
          🕢 HPC Token listing on CoinMarketCap.
          <br />
          🕢 HPC Token listing on CoinGecko.
          <br />
          🕢 HPC Token listing on CEX's.
          <br />
          🕢 Marketing, promotions and project collabs.
          <br />
          🕢 First donation to charity program's.
        </p>
      </div>
      <div className=" flex flex-col justify-center items-center my-4">
        <p className="text-[#00FF00] flex font-bold text-2xl hover:text-white transition-all duration-75 delay-100">
          Phase 2
        </p>
        <p className="text-white">
          <br />
          🕢 Finalization of HAPPYCOIN Application.
          <br />
          🕢 Android & IOS application development.
          <br />
          🕢 Website upgrade.
          <br />
          🕢 Dex liquidity aggregator integration.
          <br />
          🕢 Online payments integration.
          <br />
          🕢 Opening of HPC affiliates program.
          <br />
          🕢 Opening of merchants application program.
          <br />
          🕢 Beta testing of HAPPYCOIN App.
          <br />
          🕢 Official launching of HAPPYCOIN App.
          <br />
          🕢 Incentivized Airdrops 2.
          <br />
          🕢 Marketing, promotions and project collabs.
          <br />
          🕢Second donation to charity program's.
        </p>
      </div>
      <div className=" flex flex-col justify-center items-center my-4">
        <p className="text-[#00FF00] flex font-bold text-2xl hover:text-white transition-all duration-75 delay-100">
          Phase 3
        </p>
        <p className="text-white flex">
          <br />
          🕢 Finalization of HAPPYCOIN Blockchain App.
          <br />
          🕢 HPC blockchain core development.
          <br />
          🕢 Website upgrade.
          <br />
          🕢 HPC blockchain(testnet) deployment.
          <br />
          🕢 Beta testing of HPC blockchain(testnet).
          <br />
          🕢 HPC blockchain(mainnet) deployment.
          <br />
          🕢 Alpha testing of HPC blockchain(mainnet).
          <br />
          🕢 Migration of HPC Project(BSC to HPC Chain).
          <br />
          🕢 Official launching of HAPPYCOIN Chain(mainnet).
          <br />
          🕢 Incentivized Airdrops 3.
          <br />
          🕢 HPC Token LP's Migration into HPC Chain DEX.
          <br />
          🕢 HPC Token update into HPC Coin on CEX's.
          <br />
          🕢 HPC Coin listing on CEX's.
          <br />
          🕢 Marketing, promotions and project collabs.
          <br />
          🕢 3rd donation to charity program's.
        </p>
      </div>
    </div>
  );
};

export default milestones;
