import Staking_abi from "./abis/src/contracts/Adulam.sol/HpcStakingAbi.json";
import Staking_address from "./abis/hpcStaking.json";
import Hpc_abi from "./abis/src/contracts/Adulam.sol/HpcAbi.json";
import Hpc_address from "./abis/hpcContractAddress.json";
import { getGlobalState, setGlobalState } from "./store";
import { ethers } from "ethers";

const { ethereum } = window;
const stakingContractAddress = Staking_address.address;
const stakingContractAbi = Staking_abi.abi;
const hpcContractAddress = Hpc_address.address;
const hpcContractAbi = Hpc_abi.abi;

const getEthereumContract = () => {
  const connectedAccount = getGlobalState("connectedAccount");
  if (connectedAccount) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const stakingContract = new ethers.Contract(
      stakingContractAddress,
      stakingContractAbi,
      signer
    );
    const hpcContract = new ethers.Contract(
      hpcContractAddress,
      hpcContractAbi,
      signer
    );

    return { stakingContract, hpcContract };
  } else {
    return null;
  }
};

const connectWallet = async () => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    setGlobalState("connectedAccount", accounts[0]);
  } catch (error) {
    reportError(error);
  }
};

const stakeTokens = async (amount) => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const connectedAccount = accounts[0];
    const contracts = getEthereumContract();

    // Show loading indicator for checking and approval
    setGlobalState("loading", {
      show: true,
      msg: "Rendering HPC approval...",
    });

    // Define a handler for the StakingError event
    contracts.stakingContract.on("StakingError", (message) => {
      // Handle the event here, e.g., show an alert
      alert("Staking Error: " + message);
    });

    // Check allowance
    const allowance = await contracts.hpcContract.allowance(
      connectedAccount,
      stakingContractAddress
    );

    if (allowance < amount) {
      // Now, proceed with the approval
      await contracts.hpcContract.approve(stakingContractAddress, amount);

      // If allowance is not sufficient, introduce a delay of 15 seconds (adjust as needed)
      setGlobalState("loading", {
        show: true,
        msg: "Waiting for blockchain confirmation... ",
      });
      const delayInMilliseconds = 15000;
      await new Promise((resolve) => setTimeout(resolve, delayInMilliseconds));
    }

    // Hide loading indicator for the approval step
    setGlobalState("loading", { show: false, msg: "" });

    // Show loading indicator for the stake step
    setGlobalState("loading", {
      show: true,
      msg: "Staking your HPC token now...",
    });

    // Now, proceed with the stake function
    await contracts.stakingContract.stake(amount);

    // Hide loading indicator for the stake step
    setGlobalState("loading", { show: false, msg: "" });
  } catch (error) {
    reportError(error);
    // Hide loading indicator in case of an error
    setGlobalState("loading", { show: false, msg: "" });
  }
};

// Define a function to check and approve if needed
const checkAndApproveAllowance = async (
  contracts,
  connectedAccount,
  amount,
  stakingContractAddress
) => {
  try {
    // Check allowance
    const allowance = await contracts.hpcContract.allowance(
      connectedAccount,
      stakingContractAddress
    );

    if (allowance < amount) {
      await contracts.hpcContract.approve(stakingContractAddress, amount);
    }
  } catch (error) {
    reportError(error);
  }
};

const unstakeTokens = async (amount) => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const connectedAccount = accounts[0];
    const contracts = getEthereumContract();

    // Call the stake function
    await contracts.stakingContract.unstake(amount);
  } catch (error) {
    reportError(error);
  }
};
const Rcalculator = async (amount) => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const connectedAccount = accounts[0];
    const contracts = getEthereumContract();

    // Call the stake function
    const compute = await contracts.stakingContract.rewardCalculator(amount);
    return compute.toString();
  } catch (error) {
    reportError(error);
  }
};

const compoundRewards = async () => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const connectedAccount = accounts[0];
    const contracts = getEthereumContract();

    // Call the compoundRewards function on the staking contract
    await contracts.stakingContract.compoundRewards();
  } catch (error) {
    reportError(error);
  }
};

const claimRewards = async () => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const connectedAccount = accounts[0];
    const contracts = getEthereumContract();

    // Call the compoundRewards function on the staking contract
    await contracts.stakingContract.claimRewards();
  } catch (error) {
    reportError(error);
  }
};

const myStakes = async () => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const contracts = getEthereumContract();

    // Call the calculateRewards function with the connected user's address as the parameter
    const stakes = await contracts.stakingContract.getMyStakes();
    return stakes.toString();
  } catch (error) {
    reportError(error);
  }
};

const myRewards = async () => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const connectedAccount = accounts[0];
    const contracts = getEthereumContract();

    // Call the getMyRewards function to get rewards for the connected user
    const rewards = await contracts.stakingContract.getMyRewards({
      from: connectedAccount,
    });
    return rewards.toString();
  } catch (error) {
    reportError(error);
  }
};

const myTotalRewardsReceived = async () => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const connectedAccount = accounts[0];
    const contracts = getEthereumContract();

    // Call the getMyRewards function to get rewards for the connected user
    const rewards = await contracts.stakingContract.getMyTotalRewardsReceived({
      from: connectedAccount,
    });
    return rewards.toString();
  } catch (error) {
    reportError(error);
  }
};

const getClaimInterval = async () => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const contracts = getEthereumContract();

    // Call the getClaimInterval function to retrieve the claim interval
    const getClaimIntervail =
      await contracts.stakingContract.getClaimInterval();
    return getClaimIntervail.toString();
  } catch (error) {
    reportError(error);
  }
};

const getRewardPerStaker = async () => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const contracts = getEthereumContract();

    // Call the getClaimInterval function to retrieve the claim interval
    const getCurrentRewardPerStaker =
      await contracts.stakingContract.getCurrentRewardPerStaker();
    return getCurrentRewardPerStaker.toString();
  } catch (error) {
    reportError(error);
  }
};

const getTotalStaked = async () => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const contracts = getEthereumContract();

    // Call the totalStaked function from the smart contract to retrieve the total staked tokens
    const Staked = await contracts.stakingContract.getTotalStakes();
    return Staked.toString();
    // Do something with the 'totalStaked' value, such as displaying it or processing it further.
  } catch (error) {
    reportError(error);
  }
};

const getTotalRewardAmount = async () => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const contracts = getEthereumContract();

    // Call the totalStaked function from the smart contract to retrieve the total staked tokens
    const Staked = await contracts.stakingContract.getRewardAmount();
    return Staked.toString();
    // Do something with the 'totalStaked' value, such as displaying it or processing it further.
  } catch (error) {
    reportError(error);
  }
};

const totalStakers = async () => {
  try {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const contracts = getEthereumContract();

    // Call the getClaimInterval function to retrieve the claim interval
    const Stakers = await contracts.stakingContract.getNumberOfStakers();
    return Stakers.toString();
  } catch (error) {
    reportError(error);
  }
};

const currentAPY = async () => {
  try {
    const contracts = getEthereumContract();

    // Call the getClaimInterval function to retrieve the claim interval
    const APY = await contracts.stakingContract.getCurrentAPY();
    return APY.toString();
  } catch (error) {
    reportError(error);
  }
};
const totalContractBal = async () => {
  try {
    const contracts = getEthereumContract();

    // Call the getClaimInterval function to retrieve the claim interval
    const tBal = await contracts.stakingContract.totalContractBalance();
    return tBal.toString();
  } catch (error) {
    reportError(error);
  }
};

const reportError = (error) => {
  console.log(error.message);
  throw new Error("No ethereum object.");
};

const disconnectWallet = async () => {
  try {
    // Clear the connected account state
    setGlobalState("connectedAccount", null);
    alert("Wallet disconnected successfully.");
  } catch (error) {
    reportError(error);
  }
};

export {
  disconnectWallet,
  getEthereumContract,
  connectWallet,
  stakeTokens,
  unstakeTokens,
  compoundRewards,
  claimRewards,
  myStakes,
  myRewards,
  getClaimInterval,
  getTotalStaked,
  totalStakers,
  currentAPY,
  getRewardPerStaker,
  checkAndApproveAllowance,
  myTotalRewardsReceived,
  getTotalRewardAmount,
  Rcalculator,
  totalContractBal,
};
