import React, { useEffect, useState } from "react";
import { setAlert, useGlobalState, setGlobalState } from "../store";
import Faucetlogo from "../assets/Faucet.png";
import {
  totalRewardClaimed,
  totalCardHolders,
  claimReward,
  getRewards,
  getVaultBalance,
  gettimeElapsed,
  getCountDown,
  getRewardSymbol,
  getRewardName,
  getRewardBalance,
  connectWallet,
} from "../Rewardsv1";

const rewardsHerov1 = () => {
  const [connectedAccount] = useGlobalState("connectedAccount");
  const [vaultBal, setVaultBal] = useState("");
  const [rewards, setMyRewards] = useState("");
  const [tCardHolder, setTotalCardholder] = useState("");
  const [claimedRewards, setClaimedRewards] = useState("");
  const [timeElapsed, setTimeElapsed] = useState("");
  const [remainingTime, setCountDown] = useState("");
  const [rewardSymbol, setRewardSymbols] = useState("");
  const [rewardName, setRewardName] = useState("");
  const [rewardBalance, setRewardBalance] = useState("");
  const [claimAmount, setClaimAmount] = useState("");

  useEffect(() => {
    const fetchTotalCardHolders = async () => {
      const result = await totalCardHolders();
      setTotalCardholder(result);
    };
    const fetchRewards = async () => {
      const mRewards = await getRewards();
      const result = (mRewards / 10 ** 8).toFixed(8);
      setMyRewards(result);
    };
    const fetchVaultBalance = async () => {
      const vbalance = await getVaultBalance();
      const result = (vbalance / 10 ** 8).toFixed(8);
      setVaultBal(result);
    };
    const fetchClaimedRewards = async () => {
      const cRewards = await totalRewardClaimed();
      const result = (cRewards / 10 ** 8).toFixed(8);
      setClaimedRewards(result);
    };
    const fetchTimeElapsed = async () => {
      const cInterval = await gettimeElapsed();
      const result = (cInterval / 3600).toFixed(0);
      setTimeElapsed(result);
    };

    const fetchCountDown = async () => {
      const cDown = await getCountDown();
      const remainingTime = cDown;

      const hours = Math.floor(remainingTime / 3600);
      const minutes = Math.floor((remainingTime % 3600) / 60);
      const seconds = remainingTime % 60;

      const formattedCountDown = `${hours} hours, ${minutes} minutes, and ${seconds} seconds`;
      setCountDown(formattedCountDown);
    };
    const fetchRewardSymbol = async () => {
      const RSymbol = await getRewardSymbol();
      setRewardSymbols(RSymbol);
    };
    const fetchRewardName = async () => {
      const rName = await getRewardName();
      setRewardName(rName);
    };
    const fetchRewardBalance = async () => {
      const rBalance = await getRewardBalance();
      const result = (rBalance / 10 ** 8).toFixed(8);
      setRewardBalance(result);
    };

    fetchRewardName();
    fetchRewardSymbol();
    fetchClaimedRewards();
    fetchTotalCardHolders();
    fetchRewards();
    fetchVaultBalance();
    fetchTimeElapsed();
    fetchCountDown();
    fetchRewardBalance();
  }, [connectedAccount]);

  // Function to handle the claim action
  const onClaimRewards = async (percentage) => {
    if (claimAmount == 0) {
      setAlert("Please Input an amount to CLAIM.", "red");
      return;
    }
    if (rewards == 0) {
      setAlert("No claimable reward to CLAIM.", "red");
      return;
    }

    // if remainingTime is not equal to zero, alert
    if (remainingTime > 0) {
      setAlert("Please wait until your next claim.", "red");
      return;
    }

    setGlobalState("loading", {
      show: true,
      msg: "Claiming your rewards now...",
    });

    try {
      // Your code for handling the unstake action goes here
      const txt = await claimReward(claimAmount);
      setClaimAmount(txt);
      setGlobalState("loading", { show: true, msg: "Transaction submitted" });
      setAlert("Claiming Successful!", "green");
    } catch (error) {
      setGlobalState("loading", { show: false, msg: "" });
      setAlert("Claiming error...", "red");
      return;
    }
  };
  // Function to handle the input change for stake amount
  const handleClaimAmountChange = (event) => {
    setClaimAmount(event.target.value); // Update the stake or stakeAmount state
  };

  const calculateClaimAmount = (percentage) => {
    if (rewards !== null) {
      const selectedPercentage = parseFloat(percentage) / 100; // Convert user input to a number and then to a decimal
      const cAmount = (rewards * selectedPercentage * 10 ** 8).toFixed(0); // Convert to the desired format and set the number of decimal places to 0
      setClaimAmount(cAmount);
    }
  };

  return (
    <div
      className="w-4/6 flex justify-center items-center mx-auto py-10"
      id="rewardsSection"
    >
      <div className="flex flex-col justify-center items-center my-4">
        <img
          className="cursor-pointer rounded-full bg-white"
          src={Faucetlogo}
          alt="HPC Logo"
          style={{ width: "100px", height: "100px" }}
        />
        <p className="text-[#FDBD01] text-center text-3xl font-bold">
          REWARDS<span className="text-white ml-2">Faucet</span> <br />
        </p>
        <p className="text-white text-center text-xl">
          {"Cardholders Dashboard"}
        </p>
        <br />
        <p className="text-white text-center">
          AVAILABLE FOR REWARD
          <br /> <span className="text-[#FDBD01] font-bold">{"HAPPYCOIN"}</span>
          &nbsp;
          <span className="text-[#FDBD01] font-bold">({"HPC"})</span> <br />
          <p className="text-white text-center text-xs ">
            Network: Binance Smart Chain(BNB)
          </p>
        </p>
        <br />
        <p className="text-white text-center">
          Faucet Balance:&nbsp;
          <span className="text-[#FDBD01]">{rewardSymbol}</span>&nbsp;
          {connectedAccount ? vaultBal : "0.00000000"}
          <br />
          Your Claimable Reward:&nbsp;
          <span className="text-[#FDBD01]">{rewardSymbol}</span>&nbsp;
          {connectedAccount ? rewards : "0.00000000"}
          <br />
          Total Claimed:&nbsp;
          <span className="text-[#FDBD01]">{rewardSymbol}&nbsp;</span>
          {connectedAccount ? claimedRewards : "0.00000000"}
          <br />
          <br />
          <p className="text-white text-[#FDBD01]">
            Your Next Claim:&nbsp;
            <span className="text-white">
              {connectedAccount
                ? remainingTime
                : "0 hours, 0 minutes, and 0 seconds"}
            </span>
          </p>
        </p>
        <p className="text-white">
          Your&nbsp;<span className="text-[#FDBD01]">{rewardName}&nbsp;</span>
          Balance:&nbsp;
          <span className="text-[#FDBD01]">{rewardSymbol}</span>&nbsp;
          {connectedAccount ? rewardBalance : "0.00000000"}
        </p>
        <br />
        <div className=" text-[#1589FF] flex justify-center justify-between items-center">
          {connectedAccount ? null : (
            <span style={{ color: "#00FF00", animation: "blink 4s infinite" }}>
              {"Connect your metamask wallet to claim rewards."}
            </span>
          )}
        </div>
        &nbsp;<p className="text-white py-2">You are claiming:</p>
        <li className="w-1/3 flex justify-center items-center mx-auto">
          <input
            placeholder="Click percentage below."
            type="number"
            min="1"
            value={claimAmount}
            onChange={handleClaimAmountChange}
            className="bg-gray-200 text-black rounded py-1 px-2"
            style={{ flex: 1, width: "380px" }}
          />
          y
        </li>
        &nbsp;
        <li className="w-1/3 flex justify-center items-center mx-auto">
          {connectedAccount ? (
            <button
              className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
              onClick={() => calculateClaimAmount(25)}
            >
              25%
            </button>
          ) : (
            <button
              className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
              onClick={connectWallet}
            >
              25%
            </button>
          )}
          &nbsp;
          {connectedAccount ? (
            <button
              className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
              onClick={() => calculateClaimAmount(50)}
            >
              50%
            </button>
          ) : (
            <button
              className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
              onClick={connectWallet}
            >
              50%
            </button>
          )}
          &nbsp;
          {connectedAccount ? (
            <button
              className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
              onClick={() => calculateClaimAmount(75)}
            >
              75%
            </button>
          ) : (
            <button
              className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
              onClick={connectWallet}
            >
              75%
            </button>
          )}
          &nbsp;
          {connectedAccount ? (
            <button
              className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100"
              onClick={() => calculateClaimAmount(100)}
            >
              100%
            </button>
          ) : (
            <button className="shadow-l hover:shadow-[#FDBD01] text-black bg-white hover:bg-[#1589FF] md:text-xs p-2 rounded cursor-pointer transition-all duration-75 delay-100">
              100%
            </button>
          )}
        </li>
        <li className="w-2/4 flex justify-center items-center mx-auto">
          {connectedAccount ? (
            <button
              className="shadow-l hover:shadow-[#FDBD01] flex flex-col
            justify-center items-center  rounded
          bg-[#FDBD01] cursor-pointer text-black my-4 md:text-l p-2
            hover:bg-white hover:text-black transition-all
            duration-75 delay-100"
              onClick={onClaimRewards}
            >
              CLAIM MY REWARDS
            </button>
          ) : (
            <button
              className="shadow-l hover:shadow-[#FDBD01] flex flex-col
            justify-center items-center  rounded
          bg-[#FDBD01] cursor-pointer text-black my-4 md:text-l p-2
            hover:bg-white hover:text-black transition-all
            duration-75 delay-100"
              onClick={connectWallet}
            >
              CLAIM MY REWARDS
            </button>
          )}
        </li>
        <br />
        <p className="text-white text-center">
          <span className="text-[#FDBD01] font-bold">
            Rewards Distribution:
          </span>
          <br />
          ✅ 100% of the Faucet balance is distributed equally among all
          Cardholders based on the number of Cards they hold in their wallet.
          <br />
          <p className="text-white text-center">
            ✅ Reward claiming intervals every&nbsp;
            {connectedAccount ? timeElapsed : "24"}&nbsp;hours.
          </p>
        </p>
        <br />
        <p className="text-[#FDBD01] text-center">
          Current Eligible Cards:&nbsp;
          <span className="text-white text-sm">{tCardHolder}</span>
        </p>
        <p className="text-white text-xs justify-center items-center px-1 hover:text-[#FDBD01] transition-all duration-75 delay-100">
          Note: Perks and rewards are available to all Cardholders only.
        </p>
        <br />
        <p className="text-white text-center">
          REWARDSFaucet Contract Address:
          <br />
          <a
            href="https://bscscan.com/address/0x4c2596C9a9B7a32AA80485b251a2dCD8D488c0f2"
            target="_blank"
            alt="RewardsFaucet Contract Address"
            rel="noopener noreferrer"
          >
            <span className="text-[#1589FF]">
              0x4c2596C9a9B7a32AA80485b251a2dCD8D488c0f2
            </span>
          </a>
        </p>
        <p className="text-white text-center">
          Network: Binance Smart Chain(BNB)
        </p>
      </div>
    </div>
  );
};

export default rewardsHerov1;
