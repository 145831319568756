import telegram from "../assets/telegram_icon.png";
import twitter from "../assets/twitter-x.svg";
import facebook from "../assets/facebook_icon.png";

const Footer = () => (
  <div className="w-5/6 flex-col flex justify-center items-center mx-auto py-10 flex-col p-4 gradient-bg-footer">
    <div className="equal-column flex flex-col justify-between items-center my-4">
      <div>
        <span className="text-[#FDBD01] text-sm flex justify-center items-center p-1 hover:text-white transition-all duration-75 delay-100">
          Follow us.
        </span>
        <div className="equal-column">
          <ul className="flex flex-row justify-center space-x-2 items-center my-4">
            <a
              className="bg-white  hover:scale-50 transition-all duration-75 delay-75 rounded-full mx-2"
              href="https://t.me/OxHappyCoin"
              target="_blank"
            >
              <img className="w-10 h-10" src={telegram} alt="Telegram" />
            </a>
            <a
              className="bg-white  hover:scale-50 transition-all duration-75 delay-75 rounded-full mx-2"
              href="https://www.twitter.com/0xHappyCoin"
              target="_blank"
            >
              <img className="w-10 h-10" src={twitter} alt="Twitter" />
            </a>
            <a
              className="bg-white  hover:scale-50 transition-all duration-75 delay-75 rounded-full mx-2"
              href="https://www.facebook.com/0xHappyCoin"
              target="_blank"
            >
              <img className="w-10 h-10" src={facebook} alt="Facebook" />
            </a>
          </ul>
          <p className="text-white text-sm flex justify-center">
            Contact Us: support@myhappycoin.com
          </p>
        </div>
        <div className="equal-column text-white text-semibold justify-center flex items-center text-[#1589FF] p-2 p-1 hover:text-white transition-all duration-75 delay-100">
          Donate|Support: Our Project
        </div>
        <div className="equal-column text-white text-sm flex justify-center items-center">
          <ul>
            <a
              className="text-white flex justify-center items-center p-1 hover:text-[#1589FF] transition-all duration-75 delay-100"
              href="https://www.blockchain.com/explorer/addresses/btc/bc1qvxh4r9usam2stsg9h3ek6kcys2kq4c7f8m0crf"
              target="_blank"
              alt="Btc Donation link"
              rel="noopener noreferrer"
            >
              Bitcoin: bc1qvxh4r9usam2stsg9h3ek6kcys2kq4c7f8m0crf
            </a>
            <a
              className="text-white flex justify-center items-center p-1 hover:text-[#1589FF] transition-all duration-75 delay-100"
              href="https://bscscan.com/address/0xD77f4071f76714c94A12d880144a9ab7f3A2FeAD"
              target="_blank"
              alt="EVM Donation link"
              rel="noopener noreferrer"
            >
              ETH|BNB|Polygon|HPC: 0xD77f4071f76714c94A12d880144a9ab7f3A2FeAD
            </a>
          </ul>
        </div>
        <br />
        <div className="flex justify-center items-center">
          <p className="w-4/6 text-white text-xs my-4">
            Risk Disclosure:&nbsp;
            <span className="text-[#C2DFFF]">
              Engaging in crypto project involves a high degree of risk and
              requires careful consideration of one's financial situation, and
              risk tolerance. It is important to seek professional advice before
              participating in this project as the information provided here is
              for educational and experimental purposes only and does not
              constitute assurance of monetary return or financial or investment
              advice. This project may face various risks, including
              cyberattacks, delays, technical difficulties, and other challenges
              that could impact its viability and success. Please note that
              HAPPYCOIN Management team is not liable for any losses you may
              incur. If you do not agree with these risks, please refrain from
              using this dApp.
            </span>
          </p>
        </div>
      </div>
    </div>
    <span className="text-white text-xs">
      HAPPYCOIN © 2023. All Rights Reserved.
    </span>
  </div>
);

export default Footer;
